<template>
  <div class="score-container">
    <h1>Music Score</h1>
    <div class="score-wrapper" v-if="scoreUrl">
      <img src="/music-scores/holy-holy-holy.jpg" alt="music score" />
    </div>
  </div>
</template>

<script>
import { getScoreById } from '@/api/scores.js';

export default {
  name: 'Playlist',
  props: {
    id: { type: String },
  },
  data() {
    return { scoreUrl: null };
  },
  methods: {
    async load() {
      try {
        this.scoreUrl = await getScoreById(this.id);
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    this.load();
  },
};
</script>

<style scoped>
.score-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.score-wrapper {
  height: 100%;
}
.score-wrapper img {
  height: 100%;
}
</style>
